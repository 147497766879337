import React, { Component } from "react"

import { Layout, SEO } from "../../components"
import styles from "./index.module.scss"

class ServicePage extends Component {
	render() {
		const { pageContext } = this.props
		const { uid = "", locale = "", url, seo, heading, description } =
			pageContext.node || {};

		const extension = {
			contenttype:(pageContext?.contentType || ''),
			uid:uid,
			locale:locale
		}

		return (
			<Layout className={styles.genericTemplate} pageURL={url}>
				{seo &&
					<SEO pageURL={url} {...seo} extension={extension} />
				}

				{(heading || description) && (
					<div className="box-10080">
						<div className="container container--980">
							{heading && <h1 className="title-lineover left">{heading}</h1>}
							{description && (
								<div
									className="p__body1"
									dangerouslySetInnerHTML={{ __html: description }}
								/>
							)}
						</div>
					</div>
				)}
			</Layout>
		)
	}
}

export default ServicePage
